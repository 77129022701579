import React from "react"
import Base from "./Base"

import ComponentParser from "../helpers/ComponentParser"


const FeauresPageTemplate = ({ pageContext }) =>  {
  const {contentFiles, contentParsed} = pageContext

  return (
    <Base context={pageContext}>
      {/* {pageContext.isFrontPage ? <ComponentParser key={pageContext} content={pageContext.blocks} /> : <section className="section-bottom"><div className="inner-div"><div className="page-content" dangerouslySetInnerHTML={{ __html: pageContext.content }} /></div></section>} */}
      { <ComponentParser key={pageContext} content={pageContext.blocks} contentParsed={contentParsed} files={contentFiles} />}
    </Base>
  )
}

export default FeauresPageTemplate